<template>
  <div class="groundewater-section3">
    <div class="headline-list d-flex align-items-center mt-5 mb-4">
      <span class="">ส่วนที่ 3 : </span>
      <span class="pl-2 weight-700"
        >ข้อคิดเห็นและข้อเสนอแนะอื่นๆ เพิ่มเติม</span
      >
    </div>

    <div class="blue-box mb-4">
      <div class="header d-flex justify-content-between">
        <span class="weight-700">ข้อคิดเห็นและข้อเสนอแนะอื่นๆ เพิ่มเติม</span>
      </div>
      <div class="content">
        <textarea
          class="form-control w-50"
          rows="5"
          style="resize: none"
          v-model="feedback"
        ></textarea>

        <div>
          <div class="d-flex justify-content-between align-items-center my-5">
            <div class="weight-700">รายชื่อผู้ให้ข้อมูล</div>
          </div>
          <div class="row mx-0">
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ชื่อ - นามสกุล <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person1.name"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ตำแหน่ง <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person1.position"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >หน่วยงาน <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person1.department"
                  />
                </div>
              </div>
            </div>
            <div class="col-3 pr-0">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >เบอร์โทร <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person1.phone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ชื่อ - นามสกุล <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person2.name"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ตำแหน่ง <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person2.position"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >หน่วยงาน <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person2.department"
                  />
                </div>
              </div>
            </div>
            <div class="col-3 pr-0">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >เบอร์โทร <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person2.phone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ชื่อ - นามสกุล <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person3.name"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >ตำแหน่ง <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person3.position"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >หน่วยงาน <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person3.department"
                  />
                </div>
              </div>
            </div>
            <div class="col-3 pr-0">
              <div class="form-group row">
                <label for="input" class="col-form-label"
                  >เบอร์โทร <span class="pl-4">:</span></label
                >
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="person3.phone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blue-box -->

    <div class="row justify-content-center mt-5">
      <div class="col-4 text-center">
        <button type="button" class="btn btn-back" @click="prevCurSection">
          ย้อนกลับ
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-submit" @click="submitForm">
          บันทึก และดูผลการประเมิน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "groundwater-section3",
  props: {
    contacts: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => null,
    },
    toReload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      feedback: null,
      person1: {
        name: null,
        position: null,
        department: null,
        phone: null,
      },
      person2: { name: null, position: null, department: null, phone: null },
      person3: { name: null, position: null, department: null, phone: null },
    };
  },
  created() {
    window._ground3 = this;
  },
  methods: {
    prevCurSection() {
      this.$emit("updateCurSection", 2);
      window.scrollTo(0, 0);
    },
    bindSurveyDetail() {
      this.feedback = this.contacts.feedback;

      try {
        this.person1 = this.contacts.person[0] || {
          name: null,
          position: null,
          department: null,
          phone: null,
        };
        this.person2 = this.contacts.person[1] || {
          name: null,
          position: null,
          department: null,
          phone: null,
        };
        this.person3 = this.contacts.person[2] || {
          name: null,
          position: null,
          department: null,
          phone: null,
        };
      } catch (e) {
        console.log(e);
      }
    },
    returnContacts() {
      let persons = [];

      if (!!this.person1.name) {
        persons.push({ ...this.person1 });
      }

      if (!!this.person2.name) {
        persons.push({ ...this.person2 });
      }

      if (!!this.person3.name) {
        persons.push({ ...this.person3 });
      }

      const result = {
        Feedback: this.feedback,
        Person: persons,
      };

      return result;
    },
    submitForm() {
      //call service
      //return
      this.$emit("updateContacts", this.returnContacts());
      this.$emit("gotoSubmit", true);
    },
  },
  watch: {
    toReload(val, oldVal) {
      if (val) {
        //check mode
        if (this.mode === "edit") {
          //get form detail
          this.bindSurveyDetail();
        }
      }
    },
  },
};
</script>

<style></style>
