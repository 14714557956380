<template>
  <div class="groundwater-section1">
    <div class="headline-list d-flex align-items-center mt-5 mb-4">
      <span class="">ส่วนที่ 1 : </span>
      <span class="pl-2 weight-700">ข้อมูลทั่วไป</span>
    </div>

    <div class="blue-box mb-4 part1">
      <div class="header weight-700">1) ข้อมูลหมู่บ้าน</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">จังหวัด</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="provinceList"
                  v-model="form.provinceId"
                  placeholder="จังหวัด"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeProvince($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">อำเภอ</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="amphurList"
                  v-model="form.amphorId"
                  placeholder="อำเภอ"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeAmphur($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ตำบล</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="tambolList"
                  v-model="form.tambolId"
                  placeholder="ตำบล"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeTambol($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ชื่อหมู่บ้าน</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="villageList"
                  v-model="form.villageId"
                  placeholder="เลือกชื่อหมู่บ้าน"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  @change="changeVillage($event)"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ชื่อระบบประปา</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.systemPlumbingName"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">หมู่ที่</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.villageNo"
                />
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">จำนวนประชากร</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.population"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ครัวเรือน</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.houseHold"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 my-4">
            เลือกกรอกค่าพิกัดที่ตั้ง เป็น UTM หรือ Lat/Long
          </div>
          <div class="col-12 col-sm-1"></div>
          <div class="col-6 col-sm-2">
            <div class="variable-data">
              <iconify
                icon="ant-design:caret-right-outlined"
                class="font-deeporange mr-3"
              />ค่า UTM
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">โซน </label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="zoneList"
                  v-model="form.utmZone"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">พิกัดหมู่บ้าน X </label>
              <div class="w-100">
                <input type="number" class="form-control" v-model="form.utme" />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">พิกัดหมู่บ้าน Y</label>
              <div class="w-100">
                <input type="number" class="form-control" v-model="form.utmn" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-1"></div>
          <div class="col-6 col-sm-2">
            <div class="variable-data">
              <iconify
                icon="ant-design:caret-right-outlined"
                class="font-deeporange mr-3"
              />
              ค่าละติจูด / ลองจิจูด
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">กำหนดค่าละติจูด </label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.latitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label"
                >กำหนดค่าลองจิจูด
              </label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.longitude"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part2">
      <div class="header weight-700">2) ประเภทของระบบประปาบาดาล</div>
      <div class="content ground-water-type">
        <div class="row mb-3">
          <div class="col-12 mb-3 my-radio">
            <b-form-radio
              v-model="form.groundWaterType"
              name="groundWaterType"
              value="1"
              unchecked-value=""
              class="d-flex align-items-center"
            >
              ก. สูบจ่ายตรงจากบ่อน้ำบาดาล (สูบน้ำดิบจากบ่อน้ำบาดาล ขึ้นหอถังสูง
              และจ่ายน้ำให้กับหมู่บ้าน)
            </b-form-radio>
          </div>
          <div class="col-12 align-items-center d-flex">
            <span class="ml-5">รูปแบบของหน่วยงาน</span>
            <div class="ml-3 d-inline">
              <input
                type="text"
                class="form-control"
                :disabled="form.groundWaterType != 1"
                v-model="groundWaterTypeDepartmentTemp1"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3 my-radio">
            <b-form-radio
              v-model="form.groundWaterType"
              name="groundWaterType"
              value="2"
              unchecked-value=""
              class="d-flex align-items-center"
            >
              ข. สูบจ่ายตรงมีถังน้ำใส (สูบน้ำดิบจากบ่อน้ำบาดาล ลงถังน้ำใส
              แล้วสูบน้ำขึ้นหอถังสูง และจ่ายน้ำให้กับหมู่บ้าน)
            </b-form-radio>
          </div>
          <div class="col-12 align-items-center d-flex">
            <span class="ml-5">รูปแบบของหน่วยงาน</span>
            <div class="ml-3 d-inline">
              <input
                type="text"
                class="form-control"
                :disabled="form.groundWaterType != 2"
                v-model="groundWaterTypeDepartmentTemp2"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3 my-radio">
            <b-form-radio
              v-model="form.groundWaterType"
              name="groundWaterType"
              value="3"
              unchecked-value=""
              class="d-flex align-items-center"
            >
              ค. กรองน้ำบาดาล (สูบน้ำดิบจากบ่อน้ำบาดาล ผ่านระบบกรองน้ำ
              ลงถังน้ำใส แล้วสูบน้ำขึ้นหอถังสูงและจ่ายน้ำให้กับหมู่บ้าน)
            </b-form-radio>
          </div>
          <div class="col-12 align-items-center d-flex">
            <div class="ml-5 d-inline d-flex align-items-center">
              <span>รูปแบบของหน่วยงาน</span>
              <div class="ml-3 d-inline">
                <input
                  type="text"
                  class="form-control"
                  :disabled="form.groundWaterType != 3"
                  v-model="groundWaterTypeDepartmentTemp3"
                />
              </div>
            </div>
            <div class="ml-5 d-inline d-flex align-items-center">
              <span>อัตราการผลิต</span>
              <div class="ml-3 d-inline">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control pwa-capacity"
                    :disabled="form.groundWaterType != 3"
                    v-model="pwaCapacityTemp3"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ลบ.ม/ซม.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3 my-radio">
            <b-form-radio
              v-model="form.groundWaterType"
              name="groundWaterType"
              value="4"
              unchecked-value=""
              class="d-flex align-items-center"
            >
              อื่นๆ
            </b-form-radio>
          </div>
          <div class="col-12 align-items-center d-flex">
            <div class="ml-5 d-inline d-flex align-items-center">
              <span>รูปแบบของหน่วยงาน</span>
              <div class="ml-3 d-inline">
                <input
                  type="text"
                  class="form-control"
                  :disabled="form.groundWaterType != 4"
                  v-model="groundWaterTypeDepartmentTemp4"
                />
              </div>
            </div>
            <div class="ml-5 d-inline d-flex align-items-center">
              <span>อัตราการผลิต</span>
              <div class="ml-3 d-inline">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control pwa-capacity"
                    :disabled="form.groundWaterType != 4"
                    v-model="pwaCapacityTemp4"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ลบ.ม/ซม.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part3">
      <div class="header weight-700">3) พิกัดที่ตั้งระบบประปา</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด UTM N(Y)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.groundwaterUTMN"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">E(X)</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.groundwaterUTME"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Zone</label>
              <div class="w-100">
                <select2
                  class="select-custom"
                  :options="zoneList"
                  v-model="form.groundwaterUTMZone"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center"></div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ค่าพิกัด Latitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.groundwaterLatitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">Longitude</label>
              <div class="w-100">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.groundwaterLongitude"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">ปีที่สร้าง</label>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="form.createYear"
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 align-items-center">
            <div class="d-flex form-group">
              <label class="col-form-label">โดยหน่วยงาน</label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.createDepartment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4" ref="managementBy_ScrollTo">
      <div class="header weight-700">4) บ่อน้ำบาดาลของหน่วยงานใด</div>
      <div class="content">
        <div class="row">
          <div class="col-sm-6 part4">
            <b-form-radio-group
              class="my-radio"
              v-model="form.ownerDepartment"
              name="ownerDepartment"
              stacked
            >
              <b-form-radio value="1" class="mb-3"
                ><span class="m-0"> ก. กรมทรัพยากรน้ำบาดาล</span></b-form-radio
              >
              <b-form-radio value="2" class="mb-3 d-flex align-items-center"
                ><span class="m-0">ข. อบต./อบจ./เทศบาล </span>
                <div class="row ml-5">
                  <label for="input" class="col-form-label">ระบุ</label>
                  <div class="ml-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="ownerDepartmentOtherDetailText1"
                      :disabled="form.ownerDepartment != '2'"
                    />
                  </div>
                </div>
              </b-form-radio>
              <b-form-radio value="3" class="d-flex align-items-center"
                ><span class="m-0" style="width: 180px">ค. อื่นๆ </span>
                <div class="row ml-5">
                  <label for="input" class="col-form-label">ระบุ</label>
                  <div class="ml-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="ownerDepartmentOtherDetailText2"
                      :disabled="form.ownerDepartment != '3'"
                    />
                  </div>
                </div>
              </b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="col-sm-6 part4_2">
            <div class="frame-data-blue">
              <div class="row">
                <div class="col-sm-6">
                  <div class="d-flex form-group">
                    <label for="input" class="col-form-label"
                      >ปี พ.ศ. ที่เจาะ
                    </label>
                    <div class="w-100">
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="form.drillYear"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 align-items-center">
                  <div class="d-flex form-group">
                    <label for="input" class="col-form-label"
                      >อายุการใช้งาน
                    </label>
                    <div class="w-100">
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="form.age"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 align-items-center">
                  <div class="d-flex form-group">
                    <label for="input" class="col-form-label">ความลึก</label>
                    <div class="w-100">
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="form.depth"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 align-items-centerุ">
                  <div class="d-flex form-group">
                    <label for="input" class="col-form-label"
                      >ปริมาณน้ำที่บ่อบาดาลให้ได้</label
                    >
                    <div class="w-100">
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="form.groundWaterCapacity"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part5">
      <div class="header weight-700">
        5) ระบบประปาแห่งนี้ บริหารโดย
        <span class="font-reddanger">(บังคับกรอก)</span>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-12">
            <b-form-radio-group
              class="my-radio"
              v-model="form.managementBy"
              name="managementBy"
              stacked
            >
              <b-form-radio value="1" class="mb-3"
                ><span class="m-0">
                  องค์การปกครองส่วนท้องถิ่น
                </span></b-form-radio
              >
              <b-form-radio value="2" class="mb-3"
                ><span class="m-0">
                  คณะกรรมการบริหารกิจการประปาหมู่บ้าน
                </span></b-form-radio
              >
              <b-form-radio value="3" class="mb-3"
                ><span class="m-0"> คณะกรรมการหมู่บ้าน </span></b-form-radio
              >
              <b-form-radio value="4" class="d-flex align-items-center"
                ><span class="m-0">อื่นๆ </span>
                <span class="ml-2">ระบุ</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.managementByDescription"
                  :disabled="form.managementBy != '4'"
                />
                <!-- <div class="row ml-5">
                  <label for="input" class="col-form-label">ระบุ</label>
                  <div class="ml-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.managementByDescription"
                      :disabled="form.managementBy != '4'"
                    />
                  </div>
                </div> -->
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part6">
      <div class="header weight-700">6) พื้นที่การให้บริการน้ำประปา</div>
      <div class="content">
        <div class="row">
          <div class="col-6 col-sm-4 align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >ระบบประปาแห่งนี้ให้บริการน้ำ
              </label>
              <div class="w-100">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.serviceAreaTotalVillage"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">หมู่บ้าน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">หมู่ที่ </label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.serviceAreaTotalVillageNo"
                />
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group justify-content-end">
              <div class="col-10">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.serviceAreaServeTotalHouseHold"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">ครัวเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">จำนวน </label>
              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.serviceAreaServeTotalPopulation"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">คน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="px-3 align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >จำนวนผู้ใช้น้ำจากระบบประปาฯ แห่งนี้
              </label>
              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    style="width: 200px"
                    v-model="form.serviceAreaTotalHouseHold"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">ครัวเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto align-items-center">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label">จำนวน </label>

              <div class="w-100">
                <div class="d-flex">
                  <input
                    type="number"
                    min="0"
                    style="width: 200px"
                    class="form-control"
                    v-model="form.serviceAreaTotalPopulation"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">คน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="d-flex form-group">
              <label for="input" class="col-form-label" style="min-width: 280px"
                >รายได้เฉลี่ยต่อครัวเรือน
              </label>
              <div class="">
                <div class="input-group">
                  <input
                    style="min-width: 280px"
                    type="number"
                    class="form-control"
                    v-model="form.serviceAreaPopulationIncomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท/ปี</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part7">
      <div class="header weight-700">
        7) รายรับของกิจการระบบประปาฯ ในรอบ 1 ปี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label text-right"
                >เฉลี่ยเดือนละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    style="min-width: 280px"
                    class="form-control"
                    v-model="form.serviceIncomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                    <span class="input-group-text">โดยได้จาก</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                เก็บค่าน้ำประปาในอัตราลูกบาศก์เมตรละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceIncomeSourceWaterBill"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                เก็บค่ารักษามาตรวัดน้ำ รายละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceIncomeSourceMeterFee"
                  />
                  <div class="input-group-append" style="width: 85px">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part8">
      <div class="header weight-700">
        8) รายจ่ายของกิจการระบบประปาฯ ในรอบ 1 ปี
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <span class="col-form-label text-right">เฉลี่ยเดือนละ</span>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    style="min-width: 280px"
                    class="form-control"
                    v-model="form.serviceOutcomeAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                    <span class="input-group-text"
                      >โดยมีค่าใช้จ่ายต่างๆ ดังนี้</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าไฟฟ้าในรอบ 1 ปี เฉลี่ยเดือน
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeElectricityBillAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าตอบแทนเจ้าหน้าที่ผู้ปฏิบัติงาน ในรอบ 1 ปี
                เฉลี่ยเดือนละ</label
              >
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeCompensationAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าสารเคมี อาทิ สารส้ม ปูนขาว คลอรีน ฯลฯ ในรอบ 1 ปี
                เฉลี่ยนเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeChemicalCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าซ่อมแซมเครื่องสูบน้ำ ในรอบ 1 ปี เฉลี่ยเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeMaintenanceCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ค่าซ่อมแซมท่อและอุปกรณ์ประปา ในรอบ 1 ปี เฉลี่ยเดือนละ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomePumpMaintenanceCostAverage"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                อื่นๆ (ระบุ)
              </label>
              <div class="ml-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.serviceOutcomeOtherDescription"
                  />
                </div>
              </div>
              <div class="ml-3 pl-0">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.serviceOutcomeOther"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-box mb-4 part9">
      <div class="header weight-700">
        การใช้ประโยชน์จากผลกำไรจากกิจการระบบประปาฯ ในช่วง 2 ปีที่ผ่านมา
      </div>
      <div class="content">
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label text-right"
                >การใช้ประโยชน์จากผลกำไร
              </label>
              <div class="ml-3 align-items-center d-flex">
                <b-form-radio-group
                  class="my-radio"
                  v-model="form.utilizationUsableProfit"
                  name="utilizationUsableProfit"
                >
                  <b-form-radio value="1" class="col-4"> มี</b-form-radio>
                  <b-form-radio value="2" class="col-4">ไม่มี</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label"
                ><iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ขยายกิจการประปาเพิ่มสมาชิกผู้ใช้น้ำ
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="form.utilizationIncreaseMember"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">หลังคาเรือน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                ปรับปรุงซ่อมแซมระบบประปา (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationMaintenanceDetail"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationMaintenanceCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                พัฒนาหมู่บ้านในโครงการด้านอื่นๆ (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationVillageDevelopmentDetail"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    style="min-width: 280px"
                    class="form-control"
                    v-model="form.utilizationVillageDevelopmentCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-12 pl-0">
            <div class="form-group d-flex ml-4">
              <label for="input" class="col-form-label">
                <iconify
                  icon="ant-design:caret-right-outlined"
                  class="font-greenmint"
                />
                อื่นๆ (ระบุ)
              </label>
              <div class="ml-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    style="min-width: 350px"
                    v-model="form.utilizationOtherDetail"
                  />
                </div>
              </div>
              <div class="ml-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">เป็นเงิน</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    style="min-width: 280px"
                    v-model="form.utilizationOtherCost"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-total mt-5 mb-4 d-flex">
      <div class="d-flex align-items-center px-3 w-100">
        <div class="">
          <div class="pl-2 weight-700">
            ขณะนี้ประปาหมู่บ้านมีเงินเหลือสะสมสำหรับกิจการระบบประปา
          </div>
        </div>
        <div class="ml-5">
          <div class="d-flex">
            <label for="input" class="col-form-label mr-3"> เป็นเงิน </label>
            <div class="">
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  style="min-width: 280px"
                  v-model="form.utilizationRemainingBudget"
                />
                <div class="input-group-append">
                  <span class="input-group-text">บาท</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-4 text-center">
        <button type="button" class="btn btn-back" @click="prevCurSection">
          ย้อนกลับ
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-submit" @click="nextCurSection">
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterService } from "@/services/main.service.js";

export default {
  name: "groundwater-section1",
  props: {
    formDetail: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => null,
    },
    toReload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      zoneList: [],
      provinceList: [],
      amphurList: [],
      tambolList: [],
      villageList: [],

      //ข้อ2
      groundWaterTypeDepartmentTemp1: null,
      groundWaterTypeDepartmentTemp2: null,
      groundWaterTypeDepartmentTemp3: null,
      groundWaterTypeDepartmentTemp4: null,
      pwaCapacityTemp3: null,
      pwaCapacityTemp4: null,

      // ข้อ4
      ownerDepartmentOtherDetailText1: null,
      ownerDepartmentOtherDetailText2: null,

      form: {
        // 1) ข้อมูลหมู่บ้าน
        villageId: null, // ชื่อหมู่บ้าน id
        systemPlumbingName: null, // ชื่อระบบประปา
        villageNo: null, // หมู่ที่
        tambolId: null, // ตำบล id
        amphorId: null, // อำเภอ id
        provinceId: null, // จังหวัด id
        population: null, // จำนวนประชากร int
        houseHold: null, // ครัวเรือน int
        utmZone: null, // โซน
        utme: null, // พิกัดหมู่บ้าน X
        utmn: null, // พิกัดหมู่บ้าน Y
        latitude: null, // กำหนดค่าละติจูด
        longitude: null, // กำหนดค่าลองจิจูด
        // 2) ประเภทของระบบประปาบาดาล
        groundWaterType: null,
        groundWaterTypeDepartment: null, // รูปแบบของหน่วยงาน
        pwaCapacity: null, // อัตราการผลิต
        // 3) พิกัดที่ตั้งระบบประปา
        groundwaterUTMZone: null, // Zone
        groundwaterUTME: null, // E(X)
        groundwaterUTMN: null, // ค่าพิกัด UTM N(Y)
        groundwaterLatitude: null, // ค่าพิกัด Latitude
        groundwaterLongitude: null, // Longitude
        createYear: null, // ปีที่สร้าง
        createDepartment: null, // โดยหน่วยงาน
        // 4) บ่อน้ำบาดาลของหน่วยงานใด
        ownerDepartment: null,
        ownerDepartmentDescription: null,
        drillYear: null, // ปี พ.ศ. ที่เจาะ
        age: null, // อายุการใช้งาน
        depth: null, // ความลึก
        groundWaterCapacity: null, // ปริมาณน้ำที่บ่อบาดาลให้ได้
        // ---------------------------------------------------------
        // 5) ระบบประปาแห่งนี้ บริหารโดย
        managementBy: null,
        managementByDescription: null,
        // 6) พื้นที่การให้บริการน้ำประปา
        serviceAreaTotalVillage: null, // ระบบประปาแห่งนี้ให้บริการน้ำ , หมู่บ้าน
        serviceAreaTotalVillageNo: null, // หมู่ที่
        serviceAreaServeTotalHouseHold: null, // ครัวเรือน
        serviceAreaServeTotalPopulation: null, // คน
        serviceAreaTotalHouseHold: null, // จำนวนผู้ใช้น้ำจากระบบประปาฯ แห่งนี้, ครัวเรือน
        serviceAreaTotalPopulation: null, //  จำนวน, คน
        serviceAreaPopulationIncomeAverage: null, // รายได้เฉลี่ยต่อครัวเรือน
        // 7) รายรับของกิจการระบบประปาฯ ในรอบ 1 ปี
        serviceIncomeAverage: null, // เฉลี่ยเดือนละ
        serviceIncomeSourceWaterBill: null, // เก็บค่าน้ำประปาในอัตราลูกบาศก์เมตรละ
        serviceIncomeSourceMeterFee: null, // เก็บค่ารักษามาตรวัดน้ำ รายละ
        // 8) รายจ่ายของกิจการระบบประปาฯ ในรอบ 1 ปี
        serviceOutcomeAverage: null, // เฉลี่ยเดือนละ
        serviceOutcomeElectricityBillAverage: null, // ค่าไฟฟ้าในรอบ 1 ปี เฉลี่ยเดือน, บาท
        serviceOutcomeCompensationAverage: null, // ค่าตอบแทนเจ้าหน้าที่ผู้ปฏิบัติงาน ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomeChemicalCostAverage: null, // ค่าสารเคมี อาทิ สารส้ม ปูนขาว คลอรีน ฯลฯ ในรอบ1 ปี เฉลี่ยนเดือนละ, บาท
        serviceOutcomeMaintenanceCostAverage: null, // ค่าซ่อมแซมเครื่องสูบน้ำ ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomePumpMaintenanceCostAverage: null, //  ค่าซ่อมแซมท่อและอุปกรณ์ประปา ในรอบ 1 ปี เฉลี่ยเดือนละ, บาท
        serviceOutcomeOtherDescription: null, // อื่นๆ (ระบุ)
        serviceOutcomeOther: null,

        // 9) การใช้ประโยชน์จากผลกำไรจากกิจการระบบประปาฯ ในช่วง 2 ปีที่ผ่านมา
        utilizationUsableProfit: 1, // การใช้ประโยชน์จากผลกำไร, มี ไม่มี
        utilizationIncreaseMember: null, // ขยายกิจการประปาเพิ่มสมาชิกผู้ใช้น้ำ, หลังคาเรือน
        utilizationMaintenanceDetail: null, // ปรับปรุงซ่อมแซมระบบประปา (ระบุ)
        utilizationMaintenanceCost: null, // เป็นเงิน, บาท
        utilizationVillageDevelopmentDetail: null, // พัฒนาหมู่บ้านในโครงการด้านอื่นๆ (ระบุ)
        utilizationVillageDevelopmentCost: null, // เป็นเงิน, บาท
        utilizationOtherDetail: null, // อื่นๆ (ระบุ)
        utilizationOtherCost: null, // เป็นเงิน, บาท

        utilizationRemainingBudget: null,
      },
    };
  },
  async created() {
    window._ground1 = this;

    await this.getZone();
    await this.getProvince();
  },
  methods: {
    prevCurSection() {
      this.$router.push({
        name: "AssessmentList",
      });
    },
    nextCurSection() {
      if (this.form.managementBy === null) {
        this.$refs["managementBy_ScrollTo"].scrollIntoView({
          behavior: "smooth",
        });
        return;
      } else if (this.form.managementBy == 4 && !this.managementByDescription) {
        // this.$refs["managementBy_ScrollTo"].scrollIntoView({
        //   behavior: "smooth",
        // });
        // return;
      }

      this.$emit("updateCurSection", 2);
      this.$emit("setGroundWaterType", this.form.groundWaterType);
      this.$emit("updateDetail", this.computeResult());
      window.scrollTo(0, 0);
    },
    computeResult() {
      //set ownerDepartmentDescription
      if (this.form.ownerDepartment) {
        const number = Number(this.form.ownerDepartment);

        if (number === 2) {
          this.form.ownerDepartmentDescription =
            this.ownerDepartmentOtherDetailText1;
        } else if (number === 3) {
          this.form.ownerDepartmentDescription =
            this.ownerDepartmentOtherDetailText2;
        }
      } else {
        this.form.ownerDepartmentDescription = null;
      }

      //set GroundWaterType
      if (this.form.groundWaterType) {
        const number = parseInt(this.form.groundWaterType);

        if (number == 1) {
          this.form.groundWaterTypeDepartment =
            this.groundWaterTypeDepartmentTemp1;
        } else if (number == 2) {
          this.form.groundWaterTypeDepartment =
            this.groundWaterTypeDepartmentTemp2;
        } else if (number == 3) {
          this.form.groundWaterTypeDepartment =
            this.groundWaterTypeDepartmentTemp3;
          this.form.pwaCapacity = this.pwaCapacityTemp3;
        } else if (number == 4) {
          this.form.groundWaterTypeDepartment =
            this.groundWaterTypeDepartmentTemp4;
          this.form.pwaCapacity = this.pwaCapacityTemp4;
        }
      }

      return { ...this.form };
    },
    getQueryString() {
      const query = { ...this.$route.query };
      this.fromquery = query?.fromquery;
      if (this.fromquery === 1) {
        this.bindFirstData(query);
      }
    },
    async bindFirstData(data) {
      this.form.provinceId = data.pro;
      this.form.amphorId = data.amp;
      this.form.tambolId = data.tam;
      this.form.villageId = data.village;
      this.form.utmZone = data.zone;
      this.form.utme = data.utme;
      this.form.utmn = data.utmn;
      this.form.latitude = data.latitude;
      this.form.longitude = data.longitude;
      this.form.systemPlumbingName = data.systemName;

      if (this.form.provinceId) {
        await this.getAmphur(this.form.provinceId);

        if (this.form.amphorId) {
          await this.getTambol(this.form.amphorId);

          if (this.form.tambolId) {
            await this.getVillage(this.form.tambolId);

            if (this.form.villageId) {
              this.form.villageNo = data.moo;
            }
          }
        }
      }
    },
    async bindSurveyDetail() {
      const ground = this.formDetail.qualitySurveyGroundWater;

      this.form = {
        ...this.formDetail,
        systemPlumbingName: this.formDetail.name,

        // 2) ประเภทของระบบประปาบาดาล
        groundWaterType: ground.groundWaterType,
        groundWaterTypeDepartment: ground.groundWaterTypeDepartment,
        pwaCapacity: ground.pwaCapacity,

        //3) พิกัดที่ตั้งระบบประปา
        groundwaterUTMZone: ground.utmZone, // Zone
        groundwaterUTME: ground.utme, // E(X)
        groundwaterUTMN: ground.utmn, // ค่าพิกัด UTM N(Y)
        groundwaterLatitude: ground.latitude, // ค่าพิกัด Latitude
        groundwaterLongitude: ground.longitude, // Longitude

        // 4) บ่อน้ำบาดาลของหน่วยงานใด
        ownerDepartment: ground.ownerDepartment
          ? ground.ownerDepartment + ""
          : null,
        ownerDepartmentDescription: ground.ownerDepartmentDescription,
        drillYear: ground.drillYear,
        age: ground.age,
        depth: ground.depth,
        groundWaterCapacity: ground.groundWaterCapacity,
      };

      // 2) ประเภทของระบบประปาบาดาล
      const check2 = this.form.groundWaterType;
      if (check2 == 1) {
        this.groundWaterTypeDepartmentTemp1 =
          this.form.groundWaterTypeDepartment;
      } else if (check2 == 2) {
        this.groundWaterTypeDepartmentTemp2 =
          this.form.groundWaterTypeDepartment;
      } else if (check2 == 3) {
        this.groundWaterTypeDepartmentTemp3 =
          this.form.groundWaterTypeDepartment;
        this.pwaCapacityTemp3 = this.form.pwaCapacity;
      } else if (check2 == 4) {
        this.groundWaterTypeDepartmentTemp4 =
          this.form.groundWaterTypeDepartment;
        this.pwaCapacityTemp4 = this.form.pwaCapacity;
      }

      // 4) บ่อน้ำบาดาลของหน่วยงานใด
      const ownerDepartmentIndex = this.form.ownerDepartment || null;
      if (ownerDepartmentIndex == "2") {
        this.ownerDepartmentOtherDetailText1 =
          this.form.ownerDepartmentDescription;
      } else if (ownerDepartmentIndex == "3") {
        this.ownerDepartmentOtherDetailText2 =
          this.form.ownerDepartmentDescription;
      }

      if (this.form.provinceId) {
        await this.getAmphur(this.form.provinceId);

        if (this.form.amphorId) {
          await this.getTambol(this.form.amphorId);

          if (this.form.tambolId) {
            await this.getVillage(this.form.tambolId);
          }
        }
      }
    },
    async getZone() {
      this.zoneList = await FilterService.getListZone().then((res) => {
        return res;
      });
    },
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res.data;
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphur(provinceId = null, id = null) {
      await FilterService.getListAmphur(provinceId)
        .then((res) => {
          this.amphurList = res.data;
        })
        .catch((err) => {
          this.amphurList = [];
        });
    },
    async getTambol(amphorId = null, id = null) {
      await FilterService.getListTambol(amphorId)
        .then((res) => {
          this.tambolList = res.data;
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getVillage(tambolId = null, id = null) {
      await FilterService.getListVillage(tambolId)
        .then((res) => {
          this.villageList = res.data;
        })
        .catch((err) => {
          this.villageList = [];
        });
    },
    changeProvince(event) {
      const id = event;

      this.form.amphorId = null;
      this.form.tambolId = null;
      this.tambolList = [];
      this.form.villageId = null;
      this.villageList = [];
      this.form.villageNo = null;

      this.getAmphur(id);
    },
    changeAmphur(event) {
      const id = event;

      this.form.tambolId = null;
      this.form.villageId = null;
      this.villageList = [];
      this.form.villageNo = null;

      this.getTambol(id);
    },
    changeTambol(event) {
      const id = event;

      this.form.villageId = null;
      this.form.villageNo = null;

      this.getVillage(id);
    },
    changeVillage(event) {
      const village = this.villageList.find((f) => f.id == event);
      this.form.villageNo = village?.villageNo || null;
    },
    checkModeAndBind() {
      if (this.mode === "edit") {
        this.bindSurveyDetail();
      } else {
        this.getQueryString();
      }
    },
  },
  watch: {
    toReload(val, oldVal) {
      if (val) {
        this.checkModeAndBind();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.part1,
.part3,
.part5 {
  .form-group {
    > label {
      margin-bottom: 0;
      min-width: 180px;
      max-width: 375px;
      text-align: right;
      &:after {
        content: ":";
        margin: 0 0.75rem;
      }
    }
  }
}
.part2 {
  .ground-water-type {
    input[type="text"] {
      width: 300px;
      &.pwa-capacity {
        width: 200px;
      }
    }
  }
}

.part4_2 {
  .form-group {
    > label {
      margin-bottom: 0;
      min-width: 210px;
      max-width: 210px;
      text-align: right;
      &:after {
        content: ":";
        margin: 0 0.75rem;
      }
    }
  }
}

.part4 {
  label {
    margin-right: 10px;
  }

  span {
    width: 180px;
  }

  input[type="text"] {
    width: 300px;
  }
}

.part5 {
  span {
    margin-right: 10px;
  }

  input[type="text"] {
    width: 345px;
  }
}

.part6 {
  .form-group {
    > label {
      margin-bottom: 0;
      min-width: 120px;
      max-width: 120px;
      text-align: right;
      &:after {
        content: ":";
        margin: 0 0.75rem;
      }
    }
  }
}
</style>
